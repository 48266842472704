@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~handsontable/dist/handsontable.full.css";
@import '~antd/dist/antd.css';

button {
  box-sizing: border-box;
}

button:focus,
a:focus {
  outline: none;
}

.App {
  box-sizing: unset;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.inheritance-portal ul {
  margin: 0;
  padding: 0;
  padding-left: 14px;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

/* 
	used for select dropdown/input used in Generate Synthetic data dialog - only to disable overflow on the whole page, and to enable it on the dialog itself 
*/
.dropdown-input-portal {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 240px;
}

.app-toaster {
  text-align: center;
}

.bp3-overlay,
.bp3-transition-container {
  z-index: 99999;
}
