.cp_tree-table {
    font-size: 14px;
    color: #040402;
    background: #fff;
    border: 1px solid rgba(55, 53, 47, 0.2);
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
}

/* Header */

.cp_tree-table .cp_tree-table_header {
    color: rgba(55, 53, 47, 0.6);
    background: rgba(242, 241, 238, 0.6);
    border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

/* Employees & Expenses */

.cp_tree-table .cp_tree-table_header-cell>.t-right {
    width: 100%;
    text-align: right;
}

/* Cells */

.cp_tree-table .cp_tree-table_cell,
.cp_tree-table .cp_tree-table_header-cell {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

/* Editable cell */

.cp_tree-table .cp_tree-table_cell>input {
    font-size: 14px;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 5px 8px;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 1px rgba(55, 53, 47, 0.16);
    border: 1px solid transparent;
}

.cp_tree-table .cp_tree-table_cell>input:hover {
    box-shadow: inset 0 0 0 1px #0f55eb;
}

.cp_tree-table .cp_tree-table_cell>input:focus {
    background: #edf6fc;
    box-shadow: inset 0 0 0 1px #0f55eb;
}

/* Index cell  */

.cp_tree-table .cp_tree-table_cell .toggle-button {
    position: relative;
    display: inline-block;
    border: none;
    height: 14px;
    vertical-align: middle;
    padding: 0 5px 0 0;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
    outline: none;
}

.cp_tree-table .cp_tree-table_cell .toggle-button::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #040402 transparent transparent transparent;
    padding: 0;
    vertical-align: middle;
}

.cp_tree-table .cp_tree-table_cell .toggle-button+span {
    vertical-align: middle;
}

.cp_tree-table .cp_tree-table_cell>.without-children>span {
    padding-left: 15px;
}

.cp_tree-table .cp_tree-table_cell>.with-children>span {
    font-weight: 600;
}

/* Employees cell */

.cp_tree-table .cp_tree-table_cell>.employees-cell {
    width: 100%;
    text-align: right;
}

/* Expenses cell */

.cp_tree-table .cp_tree-table_cell>.expenses-cell {
    font-family: 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', Menlo, Courier, monospace;
    font-weight: 400;
    width: 100%;
    text-align: right;
    padding-right: 10px;
}
